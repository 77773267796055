<template>
  <div
      class="min-h-100 d-flex justify-content-center align-items-center refine-page flex-column position-relative pt-5">

    <div class="messages z-index-100 position-absolute text-white-50">
      <transition-group name="messageList">
        <div class="message" v-for="m in messages" :key="m.id">
          <div class="d-inline-flex px-4 flex-row align-items-center justify-content-center">
            {{ m.message }}
          </div>
        </div>
      </transition-group>
    </div>

    <div class="outcome-wrapper cursor-pointer mb-5" @click="removeItem">
      <div class="bg-item-container"></div>
      <div class="wrapper" ref="wrapper">
        <div class="border" id="border" ref="border"></div>
        <div class="main-element d-flex justify-content-center align-items-center cursor-pointer">
          <Item :item="getItemToRefine" hide-quantity v-if="getItemToRefine"/>
        </div>
      </div>
      <div class="outcome pe-none" id="outcome" ref="outcome"></div>
    </div>

    <div class="btn-refine text-center mb-3">
      <button @click="refine" :disabled="inProgress || !getItemToRefine" class="btn btn-lg border-0 text-white-50">Refine</button>
      <div style="transform: translateY(-13px);font-size: 12px;" class="text-white-50">
        <div v-if="refineChance"
        :class="{
          'text-success': refineChance > 80,
          'text-warning': refineChance <= 80,
          'text-danger': refineChance < 60
        }">{{ refineChance.toFixed(2) }}%</div>
        <div v-else>0%</div>
      </div>

    </div>

    <div class="d-flex flex-column flex-md-row">
      <div class="bg-stat-container p-5 me-lg-4 mb-5 mb-lg-0">
        <p v-if="!getItemToRefine" class="text-white-50">Select an item in your inventory and press "Refine"</p>
        <table class="table text-muted table-bordered border-light border-opacity-25" v-if="getItemToRefine">
          <thead>
          <tr>
            <th>Stat</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(stat, index) in getItemToRefine.ItemAttributes.filter(s => recipe && recipe.refine.stats.includes(s.attribute))"
              :key="index">
            <td>{{ formatAttribute(stat.attribute) }}</td>
            <td>{{ stat.value }}
              <span class="text-success"
                    v-if="!stat.attribute.endsWith('Speed')">+{{ (tier * 0.2).toFixed(2) }}</span>
              <span class="text-success" v-else>+{{ (0.1 * (tier * 2)).toFixed(2) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-recipe-container p-5">
        <div class="d-flex flex-row" v-if="getItemToRefine && recipe">
          <Item :item="{Item: ing.item}"
                v-for="(ing, i) in recipe.requires"
                :key="i"
                class="me-2"
                :size="48"
                :quantity="Math.floor((ing.amount * 0.15))"
                :invalid-amount="invalidQuantity(ing)"/>
          <Item :item="{Item: recipe.refine.essence.item}"
                class="me-2"
                :size="48"
                :hide-quantity="false"
                :quantity="Math.floor((recipe.refine.essence.amount))"
                :invalid-amount="hasEnoughEssence"/>
        </div>
      </div>
    </div>

  </div>
</template>
<style lang="scss">

.refine-page {

  display: flex;
  justify-content: center;
  align-items: center;

  .btn-refine {
    background: url('/img/backgrounds/refining/button.png');
    background-size: 100% 100%;
    image-rendering: pixelated;
    font-family: 'Press Start 2P', cursive;

    button {
      padding: 15px 50px 30px 50px !important;
    }

    &:hover {
      background: url('/img/backgrounds/refining/button_hover.png');
      background-size: 100% 100%;

    }
  }

  .bg-stat-container {
    background: url('/img/backgrounds/refining/stat_bg.png');
    background-size: 100% 100%;
    height: 380px;
    image-rendering: pixelated;
    width: 400px;
    max-width: 100vw;
  }

  .bg-item-container {
    background: url('/img/backgrounds/refining/item_holder_bg.png');
    background-size: 100% 100%;
    height: 150px;
    image-rendering: pixelated;
    width: 150px;
    position: absolute;
    left: -25px;
    top: -25px;
    max-width: 100vw;
  }

  .bg-recipe-container {
    background: url('/img/backgrounds/refining/recipe_bg.png');
    background-size: 100% 100%;
    height: 280px;
    image-rendering: pixelated;
    width: 260px;
    max-width: 100vw;
  }

  .wrapper, .outcome-wrapper {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 10;
  }

  .outcome-wrapper {
    overflow: visible;
  }

  .main-element {
    position: absolute;
    top: 1%;
    left: 1%;
    display: block;
    height: 98%;
    width: 98%;
    margin: auto;
    align-self: center;
    background: #1a1f2e;
    z-index: 1;
  }

  .outcome {
    border: 2px solid var(--color);
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all ease-in-out 2s;
    z-index: 10;
    box-shadow: 0 0 35px 3px transparent;

    &.success {
      --color: lime;
      opacity: 1;
      animation: outcomeSuccess 3s;
    }

    &.fail {
      --color: red;
      opacity: 1;
      animation: outcomeFail 3s;
    }
  }

  .border {
    --color: gold;
    position: absolute;
    display: block;
    top: -100%;
    left: -100%;
    z-index: -9;
    height: 200%;
    width: 200%;
    transform: rotate(0deg);
    overflow: hidden;
    background: linear-gradient(-45deg, rgba(2, 0, 36, 1) 0%, var(--color) 10%, rgba(0, 0, 0, 1) 15%, var(--color) 25%, rgba(0, 0, 0, 1) 40%, var(--color) 50%, rgba(0, 0, 0, 1) 60%, var(--color) 70%, rgba(0, 0, 0, 1) 80%, var(--color) 90%, rgba(0, 0, 0, 1) 100%);
    background-size: 50% 200%;
    transition: background ease-in-out 0.5s;
    box-sizing: unset;

    &.animate {
      animation: shine 4s linear infinite;
    }
  }


}

@keyframes shine {
  to {
    background-position: -100% 200%;
  }
}

@keyframes outcomeSuccess {
  0% {
    box-shadow: 0 0 35px 3px lime;
  }

  80% {
    box-shadow: 0 0 35px 5px lime;
  }

  100% {
    box-shadow: 0 0 0 0 lime;
  }
}

@keyframes outcomeFail {
  0% {
    box-shadow: 0 0 35px 3px red;
  }

  80% {
    box-shadow: 0 0 35px 5px red;
  }

  100% {
    box-shadow: 0 0 0 0 red;
  }
}

</style>
<script>
import {mapGetters} from "vuex";
import Item from "@/components/items/Item.vue";
import {startCase} from "lodash";

export default {
  name: 'Refining',
  components: {Item},
  data() {
    return {
      inProgress: false,
      messages: [],
    }
  },
  mounted() {
    this.$socket.emit("auth:refine chance");
  },
  methods: {
    refine() {
      this.inProgress = true;
      this.$socket.emit("auth:refine");
    },
    invalidQuantity(item) {
      if (!item || !item.item)
        return false;

      const reqIngredient = item;
      const inventoryItem = this.inventory.find(s => {
        if (s.item_id === reqIngredient.item.id)
          return s.item_id === reqIngredient.item.id && s.amount >= Math.ceil(reqIngredient.amount * 0.15)
      });

      return !inventoryItem;
    },
    removeItem() {
      if (!this.inProgress) {
        this.$socket.emit("auth:refine remove item");
        this.resetAnimations();
      }
    },
    formatAttribute(attr) {
      if (attr === 'levelRequirement')
        attr = 'Requires'

      return startCase(attr)
    },
    resetAnimations() {
      this.$refs['border'].classList.remove('animate');
      this.$refs['outcome'].classList.remove('success', 'fail');
    }
  },
  computed: {
    ...mapGetters({
      inventory: "user/inventory",
      refineChance: "user/refineChance",
      recipes: "config/zones"
    }),
    tier() {
      const name = this.getItemToRefine?.Item.name.toLowerCase()
      if (name.startsWith('leather') || name.startsWith('wooden')) {
        return 1;
      } else if (name.startsWith('stone')) {
        return 2;
      } else if (name.startsWith('iron')) {
        return 3;
      } else if (name.startsWith('gold')) {
        return 4;
      } else if (name.startsWith('emerald')) {
        return 5;
      } else if (name.startsWith('diamond')) {
        return 6;
      }

      return 1;
    },
    hasEnoughEssence() {
      const essence = this.recipe.refine.essence.item;
      const amount = this.recipe.refine.essence.amount;

      return !this.inventory.find(s => s.item_id === essence.id && s.amount >= amount)
    },
    getItemToRefine() {
      return this.inventory?.find(i => i.refine)
    },
    recipe() {
      return this.recipes && this.recipes.filter(z => z.skill === 'crafting')
        .find(recipe => recipe.outcome.name === this.getItemToRefine.Item.name)
    }
  },
  sockets: {
    RESET_ANIMATIONS() {
      this.resetAnimations()
    },
    CANCEL_REFINE() {
      this.inProgress = false;
    },
    START_REFINE() {
      this.$refs['border'].classList.remove('animate');
      // void this.$refs['border'].offsetWidth;
      this.$refs['border'].classList.add('animate');
      this.$refs['outcome'].classList.remove('success', 'fail');
    },
    REFINE_RESULT(result) {
      this.$refs['outcome'].classList.add(result);
      setTimeout(() => {
        this.$refs['border'].classList.remove('animate');
      }, 1000);

      setTimeout(() => {
        this.inProgress = false;
      }, 1500);
    },

    'GAIN_XP': function (skill) {
      if (skill.skill === 'refining' && this.messages.length < 5) {
        this.messages.push({
          id: this.messages.length + Math.floor(Math.random() * 100),
          message: `+${skill.xp}xp`,
        });

        setTimeout(() => {
          this.messages.splice(0, 1);
        }, 3000);
      }
    },
  }
}
</script>